* {
  margin: 0;
  padding: 0
}

.home {
  padding-right: 50px;
  padding-left: 50px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .home {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .home {
    width: 970px;
  }
}

@media (min-width: 1250px) {
  .home {
    width: 1170px;
  }
}

.add-user-button {
  margin: 20px;
}

.search {
  margin: 20px;
}

.userForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.userForm > form {
  display: flex;
  flex-direction: column;
  padding: 32px;
  min-width: 350px;
  border-radius: 8px;
  margin: 32px;
}
